<template>
  <div>
    <a-row :gutter="[20, 0]">
      <a-col :span="5">
        <a-form-item>
          <label class="filled">Valor total do boleto (R$)</label>
          <a-input
            class="travel-input"
            placeholder="Insira um valor"
            v-currency
            @keyup="verifyTotal"
            v-decorator="[
              `payment_methods_billing_ticket_total_value`,
              {
                rules: [
                  {
                    required: dynamicRequiredField(),
                    message: 'Obrigatório.',
                  },
                  {
                    validator: verifyValue,
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="7">
        <a-form-item>
          <label class="filled">Emissor</label>
          <a-select
            class="travel-input select"
            placeholder="Selecione"
            @change="onSelectBillingTicketTax"
            allowClear
            v-decorator="[
              `payment_methods_billing_ticket_tax_id`,
              {
                rules: [
                  {
                    required: dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of billingTickets.list"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col
        v-if="
          form.getFieldValue(`payment_methods_billing_ticket_tax_id`) !=
          undefined
        "
        :span="4"
      >
        <a-form-item>
          <label class="filled">Parcelas </label>
          <a-select
            class="travel-input select"
            placeholder="Parcelas"
            :disabled="
              form.getFieldValue(
                `payment_methods_billing_ticket_total_value`
              ) != undefined &&
              form.getFieldValue(
                `payment_methods_billing_ticket_total_value`
              ) != ''
                ? false
                : true
            "
            @change="calcBillingTicketInstallments"
            v-decorator="[
              `payment_methods_billing_ticket_installments`,
              {
                rules: [
                  {
                    required: dynamicRequiredField(),
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of billingTicket.installments"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col
        v-if="
          form.getFieldValue(`payment_methods_billing_ticket_tax_id`) !=
          undefined
        "
        :span="4"
      >
        <a-form-item>
          <label class="filled">Total Taxas (R$)</label>

          <a-input
            class="travel-input"
            placeholder="Total Taxas (R$)"
            :disabled="true"
            v-decorator="[`payment_methods_billing_ticket_tax_value`]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row
      v-for="(field, index) in billingTicket.installmentsFields"
      :key="index"
      :gutter="[20, 0]"
    >
      <a-col :span="5">
        <a-form-item>
          <label class="filled">Valor da {{ field.id }}ª parcela</label>
          <a-input
            class="travel-input"
            v-currency
            :placeholder="`Valor da ${field.id}ª parcela`"
            v-decorator="[
              `payment_methods_billing_ticket_installment_${field.id}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="7">
        <a-form-item class="travel-input">
          <label class="filled">Vencimento ({{ field.id }}ª parcela)</label>

          <a-date-picker
            class="travel-input"
            :placeholder="`Vencimento (${field.id}ª parcela)`"
            format="DD/MM/YYYY"
            v-mask="'##/##/####'"
            :showToday="false"
            :disabled-date="minDate"
            @change="selectExpirationDate(field.id)"
            v-decorator="[
              `payment_methods_billing_ticket_installment_${field.id}_expiration_date`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
          </a-date-picker>
        </a-form-item>
      </a-col>
    </a-row>

    <a-col :span="5">
      <a-form-item>
        <label class="filled">CPF</label>
        <a-input
          class="travel-input"
          placeholder="Insira o cpf"
          v-mask="'###.###.###-##'"
          v-decorator="[
            `payment_methods_billing_ticket_CPF`,
            {
              rules: [
                {
                  required: dynamicRequiredField(),
                  min: 9,
                  message: 'Preencha todos números.',
                },
              ],
            },
          ]"
        >
          <a-icon slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </a-col>

    <a-col :span="24">
      <a-form-item class="textarea">
        <label class="filled">Observações</label>

        <a-input
          class="travel-textarea"
          placeholder="Insira alguma observação sobre o boleto"
          type="textarea"
          v-decorator="[`payment_methods_billing_ticket_observation`]"
        >
        </a-input>
      </a-form-item>
    </a-col>
  </div>
</template>

<script>
import { parse } from "vue-currency-input";
import formatThings from "@/mixins/general/formatThings.js";
import { add, sub } from "date-fns";

import billingTicketsMixins from "@/mixins/billing-tickets/billingTicketsMixins.js";

export default {
  name: "BillingTicketTab",
  props: {
    form: Object,
    contract: Object,
    tempContract: Object,

    reservation_total_price: Number,
  },
  mixins: [formatThings, billingTicketsMixins],
  data() {
    return {
      paymentMethods: undefined,
      billingTicket: {
        installments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        installmentsFields: [],
      },
    };
  },
  mounted() {
    this.paymentMethods = this.tempContract["payment_methods"]
      ? JSON.parse(this.tempContract["payment_methods"])
      : undefined;

    this.billingTickets.filters.status.selected = "Ativo";
    this.getBillingTickets();

    this.form.setFieldsValue({
      payment_methods_billing_ticket_CPF:
        this.tempContract.payment_methods_billing_ticket_CPF,
      payment_methods_billing_ticket_tax_id:
        this.tempContract.payment_methods_billing_ticket_tax_id,
      payment_methods_billing_ticket_total_value:
        this.tempContract.payment_methods_billing_ticket_total_value,
      payment_methods_billing_ticket_observation:
        this.tempContract.payment_methods_billing_ticket_observation,
    });

    if (
      this.tempContract.payment_methods_billing_ticket_installments != undefined
    ) {
      let installments = parseInt(
        this.tempContract.payment_methods_billing_ticket_installments
      );

      this.billingTicket.installmentsFields = [];

      for (let i = 1; i < installments + 1; i++) {
        this.billingTicket.installmentsFields.push({ id: i });
      }
    }

    setTimeout(() => {
      this.form.setFieldsValue({
        [`payment_methods_billing_ticket_installments`]:
          this.tempContract.payment_methods_billing_ticket_installments,
        payment_methods_billing_ticket_tax_value:
          this.tempContract.payment_methods_billing_ticket_tax_value,
      });

      this.billingTicket.installmentsFields.forEach((installment) => {
        this.form.setFieldsValue({
          [`payment_methods_billing_ticket_installment_${installment.id}`]:
            this.tempContract[
              `payment_methods_billing_ticket_installment_${installment.id}`
            ],
          [`payment_methods_billing_ticket_installment_${installment.id}_expiration_date`]:
            this.tempContract[
              `payment_methods_billing_ticket_installment_${installment.id}_expiration_date`
            ],
        });
      });
      this.verifyTotal();
    }, 20);
  },
  methods: {
    verifyTotal() {
      this.$emit("verifyTotal");
    },
    onSelectBillingTicketTax(taxId) {
      if (taxId != undefined) {
        let tax = this.billingTickets.list.filter((e) => {
          return e.id == taxId;
        });

        this.billingTicket.installmentsFields = [];

        this.form.setFieldsValue({
          [`payment_methods_billing_ticket_installments`]: undefined,
          [`payment_methods_billing_ticket_tax_value`]: undefined,
        });

        if (tax[0] != undefined) {
          this.form.setFieldsValue({
            [`payment_methods_billing_ticket_taxes_range`]: tax[0].taxes_range,
          });
        } else {
          this.form.setFieldsValue({
            [`payment_methods_billing_ticket_taxes_range`]: "[]",
          });
          setTimeout(() => {
            this.tempContract[`payment_methods_billing_ticket_tax_id`] = "";
          }, 20);
        }
      } else {
        this.billingTicket.installmentsFields = [];
        this.tempContract.payment_methods_billing_ticket_installments =
          undefined;
      }
    },
    minDate(current) {
      return (
        sub(new Date(), {
          days: 1,
        }) > current
      );
    },
    selectExpirationDate(id) {
      if (id == 1) {
        setTimeout(() => {
          let newExpirationDate = null,
            val =
              this.tempContract[
                `payment_methods_billing_ticket_installment_${id}_expiration_date`
              ];

          this.billingTicket.installmentsFields.forEach((installment) => {
            setTimeout(() => {
              newExpirationDate = `${add(val._d, {
                months: installment.id - 1,
              })}`;

              this.form.setFieldsValue({
                [`payment_methods_billing_ticket_installment_${installment.id}_expiration_date`]:
                  newExpirationDate,
              });
            }, 20);
          });
        }, 20);
      }
    },
    calcBillingTicketInstallments() {
      let billingTicketValue =
          this.tempContract[`payment_methods_billing_ticket_total_value`],
        installments = 1,
        taxesRange =
          this.tempContract["payment_methods_billing_ticket_taxes_range"],
        selectedTax = "";

      setTimeout(() => {
        installments = this.tempContract
          .payment_methods_billing_ticket_installments
          ? parseInt(
              this.tempContract.payment_methods_billing_ticket_installments
            )
          : 1;

        if (taxesRange) {
          taxesRange = JSON.parse(taxesRange);
          selectedTax = taxesRange.filter((e) => {
            return e.id == installments;
          });
        } else {
          selectedTax = "";
          taxesRange = [];
        }

        let totalTaxes = 0;
        let totalPercentage = 0;
        taxesRange.forEach((tax) => {
          if (parseInt(tax.id) <= installments) {
            let thetax = tax.value.replace("R$ ", "").replace(",", ".");
            let thePercentage = tax.percentage.replace("%", "");

            totalTaxes += parseFloat(thetax);
            totalPercentage = parseFloat(thePercentage);
          }
        });

        selectedTax;

        if (billingTicketValue) {
          billingTicketValue = billingTicketValue.replace(",", ".");
          billingTicketValue = parseFloat(billingTicketValue);
        }

        let thePercentageDiscount = 0;
        let totalTaxesBillingTicket = 0;

        thePercentageDiscount = (totalPercentage / 100) * billingTicketValue;

        totalTaxesBillingTicket = thePercentageDiscount + totalTaxes;

        this.tempContract[`payment_methods_billing_ticket_tax_value`] =
          totalTaxesBillingTicket
            ? this.formatPrice(totalTaxesBillingTicket)
            : 0;

        if (billingTicketValue) {
          let monthlyInstallments = [],
            installmentsFields = [];

          for (let i = 1; i < installments + 1; i++) {
            monthlyInstallments.push(billingTicketValue / installments);
            installmentsFields.push({ id: i });
          }

          this.billingTicket.installmentsFields = installmentsFields;

          this.tempContract.payment_methods_billing_ticket_installments_rows =
            JSON.stringify(installmentsFields);

          this.form.setFieldsValue({
            [`payment_methods_billing_ticket_installment_1`]: this.formatPrice(
              monthlyInstallments[0]
            ),
          });

          this.billingTicket.installmentsFields.forEach((installment) => {
            setTimeout(() => {
              this.form.setFieldsValue({
                [`payment_methods_billing_ticket_installment_${installment.id}`]:
                  this.formatPrice(monthlyInstallments[0]),
              });
            }, 10);
          });
          //}
        }

        this.form.setFieldsValue({
          [`payment_methods_billing_ticket_tax_value`]: totalTaxesBillingTicket
            ? this.formatPrice(totalTaxesBillingTicket)
            : 0,
        });
      }, 20);
    },
    dynamicRequiredField() {
      let flag = this.paymentMethods;

      if (flag != undefined) {
        flag = this.paymentMethods.includes("billing-ticket");
      } else {
        flag = false;
      }

      return flag;
    },
    async verifyValue(rule, value, callback) {
      rule;
      let theValue = parse(value),
        totalValue = parseFloat(this.reservation_total_price);
      if (isNaN(theValue) || theValue == undefined) {
        theValue = 0;
      } else {
        if (parse(value) > totalValue) {
          callback(
            `O valor preenchido ultrapassa o total da reserva (R$ ${this.reservation_total_price}).`
          );
        } else {
          callback();
        }
      }
    },
  },
};
</script>
