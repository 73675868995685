var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',{attrs:{"gutter":[20, 0]}},[_c('a-col',{attrs:{"span":5}},[_c('a-form-item',[_c('label',{staticClass:"filled"},[_vm._v("Valor total do boleto (R$)")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_billing_ticket_total_value`,
            {
              rules: [
                {
                  required: _vm.dynamicRequiredField(),
                  message: 'Obrigatório.',
                },
                {
                  validator: _vm.verifyValue,
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_billing_ticket_total_value`,\n            {\n              rules: [\n                {\n                  required: dynamicRequiredField(),\n                  message: 'Obrigatório.',\n                },\n                {\n                  validator: verifyValue,\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira um valor"},on:{"keyup":_vm.verifyTotal}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":7}},[_c('a-form-item',[_c('label',{staticClass:"filled"},[_vm._v("Emissor")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_billing_ticket_tax_id`,
            {
              rules: [
                {
                  required: _vm.dynamicRequiredField(),
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_billing_ticket_tax_id`,\n            {\n              rules: [\n                {\n                  required: dynamicRequiredField(),\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input select",attrs:{"placeholder":"Selecione","allowClear":""},on:{"change":_vm.onSelectBillingTicketTax}},_vm._l((_vm.billingTickets.list),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v("\n            "+_vm._s(item.name)+"\n          ")])}),1)],1)],1),_vm._v(" "),(
        _vm.form.getFieldValue(`payment_methods_billing_ticket_tax_id`) !=
        undefined
      )?_c('a-col',{attrs:{"span":4}},[_c('a-form-item',[_c('label',{staticClass:"filled"},[_vm._v("Parcelas ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_billing_ticket_installments`,
            {
              rules: [
                {
                  required: _vm.dynamicRequiredField(),
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_billing_ticket_installments`,\n            {\n              rules: [\n                {\n                  required: dynamicRequiredField(),\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input select",attrs:{"placeholder":"Parcelas","disabled":_vm.form.getFieldValue(
              `payment_methods_billing_ticket_total_value`
            ) != undefined &&
            _vm.form.getFieldValue(
              `payment_methods_billing_ticket_total_value`
            ) != ''
              ? false
              : true},on:{"change":_vm.calcBillingTicketInstallments}},_vm._l((_vm.billingTicket.installments),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item}},[_vm._v("\n            "+_vm._s(item)+"\n          ")])}),1)],1)],1):_vm._e(),_vm._v(" "),(
        _vm.form.getFieldValue(`payment_methods_billing_ticket_tax_id`) !=
        undefined
      )?_c('a-col',{attrs:{"span":4}},[_c('a-form-item',[_c('label',{staticClass:"filled"},[_vm._v("Total Taxas (R$)")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([`payment_methods_billing_ticket_tax_value`]),expression:"[`payment_methods_billing_ticket_tax_value`]"}],staticClass:"travel-input",attrs:{"placeholder":"Total Taxas (R$)","disabled":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1):_vm._e()],1),_vm._v(" "),_vm._l((_vm.billingTicket.installmentsFields),function(field,index){return _c('a-row',{key:index,attrs:{"gutter":[20, 0]}},[_c('a-col',{attrs:{"span":5}},[_c('a-form-item',[_c('label',{staticClass:"filled"},[_vm._v("Valor da "+_vm._s(field.id)+"ª parcela")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_billing_ticket_installment_${field.id}`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_billing_ticket_installment_${field.id}`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":`Valor da ${field.id}ª parcela`}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":7}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{staticClass:"filled"},[_vm._v("Vencimento ("+_vm._s(field.id)+"ª parcela)")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_billing_ticket_installment_${field.id}_expiration_date`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_billing_ticket_installment_${field.id}_expiration_date`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":`Vencimento (${field.id}ª parcela)`,"format":"DD/MM/YYYY","showToday":false,"disabled-date":_vm.minDate},on:{"change":function($event){return _vm.selectExpirationDate(field.id)}}})],1)],1)],1)}),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',[_c('label',{staticClass:"filled"},[_vm._v("CPF")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"},{name:"decorator",rawName:"v-decorator",value:([
          `payment_methods_billing_ticket_CPF`,
          {
            rules: [
              {
                required: _vm.dynamicRequiredField(),
                min: 9,
                message: 'Preencha todos números.',
              },
            ],
          },
        ]),expression:"[\n          `payment_methods_billing_ticket_CPF`,\n          {\n            rules: [\n              {\n                required: dynamicRequiredField(),\n                min: 9,\n                message: 'Preencha todos números.',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira o cpf"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"textarea"},[_c('label',{staticClass:"filled"},[_vm._v("Observações")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([`payment_methods_billing_ticket_observation`]),expression:"[`payment_methods_billing_ticket_observation`]"}],staticClass:"travel-textarea",attrs:{"placeholder":"Insira alguma observação sobre o boleto","type":"textarea"}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }